import React from "react";
import Layout from "../components/layout";
import Breadcrumb from "../components/breadcrumb";
import PolicyItem from "../components/policy-item";
import GlobalConstants from "../helpers/constants";
import { GetSimplePathObj } from "../helpers/data-formatting";
import { Grid, Typography } from "@material-ui/core";
import { graphql } from "gatsby";
import { GetComponentByName } from "../helpers/query-data";
import { PageProps } from "gatsby";
import { GQLQuery } from "../types";

type PageInputProps = { crystallize: GQLQuery };
type PrivacyPolicyProps = PageProps<PageInputProps>;

const PrivacyPolicy = (props: PrivacyPolicyProps): JSX.Element => {
  const QueryData = props.data.crystallize.catalogue;
  const PathObj = GetSimplePathObj(GlobalConstants.PrivacyPolicy);
  const DescriptionComponent = GetComponentByName(
    QueryData.components,
    GlobalConstants.Description
  );

  return (
    <Layout>
      <Breadcrumb pathObj={PathObj} />
      <Grid item xs={12}>
        <Typography variant="h6">{QueryData.name}</Typography>
        {DescriptionComponent.content.html.map((node, index) => (
          <div
            key={index}
            className="MuiTypography-root MuiTypography-body2"
            dangerouslySetInnerHTML={{ __html: node }}
          />
        ))}
      </Grid>
      {QueryData.children.map((node, index) => (
        <Grid item xs={12} key={index}>
          <PolicyItem
            title={GetComponentByName(node.components, GlobalConstants.Title)}
            content={GetComponentByName(
              node.components,
              GlobalConstants.Content
            )}
            properties={GetComponentByName(
              node.components,
              GlobalConstants.Properties
            )}
          />
        </Grid>
      ))}
    </Layout>
  );
};

export const Query = graphql`
  query {
    crystallize {
      catalogue(path: "/articles/privacy-policy") {
        name
        components {
          name
          content {
            ...PP_richTextContent
          }
        }
        children {
          ...PP_item
        }
      }
    }
  }

  fragment PP_item on CRYSTALLIZE_Item {
    name
    components {
      name
      type
      content {
        ...PP_singleLineContent
        ...PP_richTextContent
        ...PP_properties
      }
    }
  }

  fragment PP_singleLineContent on CRYSTALLIZE_SingleLineContent {
    text
  }

  fragment PP_richTextContent on CRYSTALLIZE_RichTextContent {
    html
  }

  fragment PP_properties on CRYSTALLIZE_PropertiesTableContent {
    sections {
      ... on CRYSTALLIZE_PropertiesTableSection {
        title
        properties {
          key
          value
        }
      }
    }
  }
`;

export default PrivacyPolicy;
