import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  GQLComponent,
  GQLPropertiesTableSection,
  GQLRichTextContent,
  GQLSingleLineContent,
} from "../types";

const UseStyles = makeStyles({
  title: {
    fontWeight: "bold",
  },

  tableHead: {
    backgroundColor: "#2B2B2B",
  },

  tableHeadCell: {
    color: "white",
    fontWeight: "bold",
  },

  tableCell: {
    fontWeight: "bold",
  },
});

/* */
const GetProperties = (propertiesComponent) => {
  if (propertiesComponent !== undefined) {
    return propertiesComponent.content.sections;
  } else {
    return null;
  }
};

type PolicyItemProps = {
  title: GQLComponent;
  content: GQLComponent;
  properties: GQLPropertiesTableSection[];
};

/*
 * title -> Title of the privacy policy
 * content -> Text describing the policy
 * properties -> List of cookies (may be undefined!)
 */
const PolicyItem = (props: PolicyItemProps): JSX.Element => {
  const Classes = UseStyles();
  const Properties = GetProperties(props.properties);
  const TitleCompContent = props.title.content as GQLSingleLineContent;
  const ContentCompContent = props.content.content as GQLRichTextContent;

  return (
    <div>
      <Typography variant="body2" className={Classes.title}>
        {TitleCompContent.text}
      </Typography>
      {ContentCompContent.html.map((node, index) => (
        <div
          key={index}
          className="MuiTypography-root MuiTypography-body2"
          dangerouslySetInnerHTML={{ __html: node }}
        />
      ))}
      {Properties && (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead className={Classes.tableHead}>
              <TableRow>
                <TableCell className={Classes.tableHeadCell}>
                  COOKIE Name
                </TableCell>
                <TableCell className={Classes.tableHeadCell}>
                  COOKIE Description
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Properties[0].properties.map((node, index) => (
                <TableRow key={index}>
                  <TableCell className={Classes.tableCell}>
                    {node.key}
                  </TableCell>
                  <TableCell>{node.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default PolicyItem;
